import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';

const Search = ({
  inputMode = 'text',
  field = 'id',
  hideSubmit = false,
  disabled = false,
  placeholder,
  onSubmit,
  onChange,
  value = '',
  t,
}) => {
  const [keyword, setKeyword] = useState(value);
  const accountSettings = useSelector((state) => state.GeneralSettings.accountSettings);
  const typeAccount = accountSettings?.document_type == 'document_br'; 

  const clear = () => {
    onSubmit({ [field]: '' });
    setKeyword('');
  };

  const handleSubmit = (e) => {
    onSubmit({ [field]: keyword }, keyword);
    e?.preventDefault();
    return;
  };

  useEffect(() => {
    if (value && keyword !== value) setKeyword(value);
  }, [value]);

  return (
    <div className="d-flex flex-fill">
      <div className="search-box w-100">
        <Input
          type="text"
          inputMode={(inputMode == 'numeric' && typeAccount) ? inputMode : 'text'} 
          id="search-field"
          className="form-control search"
          placeholder={placeholder ?? t('search-by-product')}
          onChange={(e) => {
            const raw = e.target.value;
            const value = (['numeric'].includes(inputMode) && typeAccount)
              ? raw.replace(/[^0-9]/g, '')
              : raw;

            if (onChange) onChange(value);
            setKeyword(value);
          }}
          onKeyDown={(e) => {
            const enterKeys = e.code === 'Enter' || e.code === 'NumpadEnter';
            if (enterKeys) handleSubmit(e);
          }}
          onBlur={(e) => {
            if (hideSubmit) handleSubmit(e);
          }}
          value={keyword ?? ''}
          disabled={disabled}
        />

        {keyword?.length ? (
          <i
            onClick={clear}
            className="ri-close-line search-icon cursor-pointer"
          />
        ) : (
          <i className="ri-search-line search-icon"></i>
        )}
      </div>

      {!hideSubmit ? (
        <Button type="button" className="ms-2 border-0" onClick={handleSubmit}>
          <i className="ri-search-line search-icon" />
        </Button>
      ) : null}
    </div>
  );
};

export default withTranslation()(Search);
