import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';

import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import { createCustomer } from '../../slices/customers/thunk';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import Yup from '../../helpers/yup_extras';

const CreateDigitalWallet = ({
  isOpen,
  toggle,
  onSuccess,
  document,
  t,
  isCellphoneMandatory = false,
  saveButtonTitle,
  origin = 'panel',
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: '',
      document: document || '',
      birthdate: '',
      email: '',
      cellphone: '',
    },
    validationSchema: Yup.object({
      document: Yup.string()
        .when('accountSettings.document_type', {
          is: 'document_br',
          then: (schema) =>
            schema
              .required(t('validation-required-document'))
              .document(t('validation-invalid-document')),
          otherwise: (schema) => schema.notRequired(),
        }),
      email: Yup.string().email(t('validation-valid-email')),
      cellphone: isCellphoneMandatory
        ? Yup.string().required(t('validation-required-cellphone'))
        : Yup.string(),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      const newWallet = {
        document: values.document,
        email: !isEmpty(values.email) ? values.email : undefined,
        cellphone: !isEmpty(values.cellphone) ? values.cellphone : undefined,
      };

      newWallet.document = values.document
        .replaceAll('.', '')
        .replaceAll('-', '')
        .replaceAll('/', '');

      if (!isEmpty(values.name)) {
        newWallet.name = values.name;
      }

      if (!isEmpty(values.birthdate)) {
        newWallet.birthdate = moment(
          values.birthdate,
          'DD/MM/YYYY'
        ).toISOString();
      }

      // save new Contact
      const action = await dispatch(
        createCustomer({
          origin,
          customer: newWallet,
          successMessage: t('new-wallet-success'),
          conflictMessage: t('new-wallet-conflict'),
          errorMessage: t('new-wallet-error'),
        })
      );

      setIsLoading(false);

      if (!action.payload.error) {
        validation.resetForm();
        onSuccess(action.payload);
        toggle();
      }
    },
  });

  const accountSettings = useSelector((state) => state.GeneralSettings.accountSettings);

  return (
    <React.Fragment>
      <Modal id="showModal" isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader className="bg-info-subtle p-3" toggle={toggle}>
          {t('new-wallet')}
        </ModalHeader>

        <Form
          className="tablelist-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Row className="g-3">
              <Col lg={12}>
                <div>
                  <Label htmlFor="name-field" className="form-label">
                    {t('name')}
                  </Label>
                  <Input
                    name="name"
                    id="name-field"
                    className="form-control"
                    placeholder={t('name-placeholder')}
                    type="text"
                    validate={{ required: { value: false } }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ''}
                  />
                </div>
              </Col>

              <Col lg={12}>
                <div>
                  <Label htmlFor="document-field" className="form-label">
                    {t('document')}
                  </Label>

                  <Input
                    mask={
                      accountSettings?.document_type == 'document_br' ?
                        validation.values.document.length < 15
                          ? '999.999.999-999'
                          : '99.999.999/9999-99'
                        : false
                    }
                    maskChar=""
                    name="document"
                    id="document-field"
                    className="form-control"
                    placeholder={t('document-placeholder')}
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.document || ''}
                    invalid={
                      validation.touched.document && validation.errors.document
                        ? true
                        : false
                    }
                    tag={InputMask}
                  />
                  {validation.touched.document && validation.errors.document ? (
                    <FormFeedback type="invalid">
                      {validation.errors.document}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div>
                  <Label htmlFor="birthdate-field" className="form-label">
                    {t('birthdate')}
                  </Label>

                  <Input
                    mask="99/99/9999"
                    name="birthdate"
                    id="birthdate-field"
                    className="form-control"
                    placeholder={t('birthdate-placeholder')}
                    type="text"
                    validate={{
                      required: { value: false },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.birthdate || ''}
                    invalid={
                      validation.touched.birthdate &&
                        validation.errors.birthdate
                        ? true
                        : false
                    }
                    tag={InputMask}
                  />
                  {validation.touched.birthdate &&
                    validation.errors.birthdate ? (
                    <FormFeedback type="invalid">
                      {validation.errors.birthdate}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div>
                  <Label htmlFor="email-field" className="form-label">
                    {t('email')}
                  </Label>

                  <Input
                    name="email"
                    id="email-field"
                    className="form-control"
                    placeholder={t('email-placeholder')}
                    type="email"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <Label htmlFor="cellphone-field" className="form-label">
                    {t('cellphone')}
                  </Label>

                  <Input
                    name="cellphone"
                    mask="(99) 9 9999-9999"
                    id="cellphone-field"
                    className="form-control"
                    placeholder={t('cellphone-placeholder')}
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.cellphone || ''}
                    invalid={
                      validation.touched.cellphone &&
                        validation.errors.cellphone
                        ? true
                        : false
                    }
                    tag={InputMask}
                  />
                  {validation.touched.cellphone &&
                    validation.errors.cellphone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.cellphone}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button type="button" className="btn btn-light" onClick={toggle}>
                {t('close')}
              </button>
              <button type="submit" className="btn btn-tertiary" id="add-btn">
                <div className="d-flex">
                  {isLoading && <Spinner size="sm" className="me-3" />}
                  {saveButtonTitle || t('save')}
                </div>
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default withTranslation()(CreateDigitalWallet);
