/* eslint-disable react/prop-types */
import { Card, CardBody, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import _ from 'lodash';
import React from 'react';

import OrderDetailSummary from './Summary';
import TableContainer from '../../../../Components/Common/TableContainer';
import { formatCurrency } from '../../../../helpers/format';

const processItems = (items) => {
  const itemMap = {};

  return items.map((item, index) => {
    const uniqueKey = `${item.id}_${index}`;

    if (!itemMap[item.id]) {
      itemMap[item.id] = [];
    }
    itemMap[item.id].push(item);

    return { ...item, uniqueKey };
  });
};


const OrderDetailTable = (props) => {
  const { order_items, order_engine, isLoading } = useSelector(
    createSelector(
      (store) => store.Order,
      ({ order, isLoading }) => {
        const arr = order?.properties;
        const findFn = (key) => (p) => p.key === key;

        const processedItems = processItems(_.find(arr, findFn('items'))?.value || []);

        return {
          order_items: processedItems,
          order_engine: arr?.length && _.find(arr, findFn('engine'))?.value,
          isLoading,
        };
      }
    )
  );

  const columns = [
    {
      Header: props.t('order-details.data-table.product-details'),
      Cell: ({ row }) => {
        const data = row?.original;
        const code = props.t('order-details.data-table.product-code');
        const desc = props.t('order-details.data-table.product-description');

        return (
          <div className="d-flex flex-column">
            <div className="d-flex gap-1">
              <span className="fw-semibold">{code}:</span>
              <span>{data.id}</span>
            </div>

            <div className="d-flex gap-1">
              <span className="fw-semibold">{desc}:</span>
              <span>{data.description}</span>
            </div>
          </div>
        );
      },
    },

    {
      Header: props.t('order-details.data-table.product-price'),
      Cell: (props) => {
        const data = props?.row?.original;
        return formatCurrency(data.price);
      },
    },

    {
      Header: props.t('order-details.data-table.product-amount'),
      Cell: (props) => (props?.row?.original?.quantity),
    },

    {
      Header: props.t('order-details.data-table.product-generated'),
      Cell: (props) => {
        const { uniqueKey } = props?.row?.original;
        const raw = _.find(order_engine?.items, (p, idx) => `${p.id}_${idx}` === uniqueKey);
        return formatCurrency(raw?.accumulation ?? 0);
      },
    },

    {
      Header: props.t('order-details.data-table.product-total'),
      Cell: (props) => {
        const data = props?.row?.original;
        const value = data.price * (data?.quantity ?? 1);
        return formatCurrency(value);
      },
    },
  ];
  
  return (
    <Row>
      <Col>
        <span className="fs-4 fw-semibold">
          {props.t('finish-order-details')}
        </span>

        {isLoading ? (
          <div className="skeleton card" style={{ height: '250px' }} />
        ) : (
          <Card className="mt-1">
            <CardBody className="p-1 pb-0 text-muted">
              <TableContainer
                isLoading={isLoading}
                data={order_items ?? []}
                columns={columns}
                activePage={1}
                setPage={() => { }}
                pageSize={10}
                totalPages={0}
                count={0}
                className="custom-header-css"
                divClass="table-responsive mt-auto"
                tableClass="align-middle table-nowrap m-0"
                theadClass="table-light"
              />

              <OrderDetailSummary />
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default withTranslation()(OrderDetailTable);
